import { useRegisterStyle } from '@didi/ec-base';

import * as chartToken from './token';

const useStyle = (prefixCls) => {

  return useRegisterStyle('pagelet-leave-summary', () => [
    {
      // base
      ['.pagelet-leave-summary-drawer']: {
        '.ant5-drawer-header': {
          padding: '20px 16px !important',
        }
      },
      ['.pagelet-leave-summary']: {
        ['.ant-tabs>.ant-tabs-nav, .ant-tabs>div>.ant-tabs-nav']: {
          position: 'absolute',
          top: '5px',
          left: '20px',
          padding: '0px !important',
          margin: '0 0 20px 0 !important',
          ['.ant-tabs-tab']: {
            padding: '12px 8px 4px',
            margin: '0 16px 0 0 !important ',
            ['.ant-tabs-tab-btn']: {
              color: 'rgba(0, 0, 0, 0.72)',
              fontSize: '18px',
            },
          },
          ['.ant-tabs-tab-active']: {
            ['.ant-tabs-tab-btn']: {
              color: 'rgba(0, 0, 0, 0.84)',
            },
          },
          ['.ant-tabs-ink-bar.ant-tabs-ink-bar-animated']: {
            width: ' 22px !important',
            height: '4px',
            borderRadius: '4px',
          },
        },
        ['.ant-tabs-bottom>.ant-tabs-nav:before, .ant-tabs-bottom>div>.ant-tabs-nav:before, .ant-tabs-top>.ant-tabs-nav:before, .ant-tabs-top>div>.ant-tabs-nav:before']: {
          border: 'none',
        },
        ['.ant-tabs-content-holder']: {
          ['.holiday-dept-select']: {
            width: '340px',
            paddingLeft: '10px',
            ['.ant-select:not(.ant-select-customize-input) .ant-select-selector']: {
              background: '#F5F5F5',
            }
          },
          ['.holiday-not-used-page']: {
            ['.holiday-charts']: {
              display: 'flex',
              justifyContent: 'space-around',
              margin: '24px 0 32px 0',
              minHeight: '30vh',
              ['.all-charts,.top-charts']: {
                flex: '1',
                ['.title']: {
                  // text-align: center,
                  fontSize: '16px',
                  fontWeight: '500',
                  color: 'rgba(0, 0, 0, 0.85)',
                  paddingBottom: '8px',
                  paddingLeft: '12px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  ['.leave-day-people']: {
                    fontWeight: '400',
                    fontSize: '14px',
                    padding: '3px 7px',
                    background: '#F7FAFF',
                    color: 'rgba(0, 0, 0, 0.84)',
                    borderRadius: '4px',
                    ['.total-days']: {
                      color: '#0A70F5',
                      fontWeight: '500',
                    },
                  },
                },
                ['.echarts-wrapper']: {
                  position: 'relative',
                  ['.icon-annual-wrapper']: {
                    position: 'absolute',
                    top: '20px',
                    left: '77px',
                    zIndex: '100',
                  },
                  ['.icon-sick-wrapper']: {
                    position: 'absolute',
                    top: '85px',
                    left: '77px',
                    zIndex: '100',
                  },
                  ['.icon-compassionate-wrapper']: {
                    position: 'absolute',
                    top: '150px',
                    left: '77px',
                    zIndex: '100',
                  },
                  ['.icon-other-wrapper']: {
                    position: 'absolute',
                    top: '215px',
                    left: '77px',
                    zIndex: '100',
                  },
                  ['.has-top-echarts']: {
                    backgroundColor: '#F7FAFF',
                    marginLeft: '10px',
                    borderRadius: '8px',
                    paddingTop: '8px',
                  },
                },
              },
              ['.all-charts']: {
                position: 'relative',
                ['.radio-box']: {
                  position: 'absolute',
                  right: '0',
                  top: '30px',
                  transform: 'scale(0.9)',
                  zIndex: '1',
                },

                ['.percent - chart']: {
                  display: 'flex',
                  justifyContent: 'space-around',
                  alignItems: 'center',
                  height: '30vh',

                  ['.percent-item']: {
                    textAlign: 'center',
                    margin: 'auto 0',

                    ['.percent-title']: {
                      fontSize: '16px',
                      color: 'rgba(0, 0, 0, 0.65)',
                      lineHeight: '24px',
                    },
                  },
                },
              },
              ['.top-charts']: {
                flex: 1.25,
              }
            },
            ['.holiday-total-year-detail']: {
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '8px',
              paddingLeft: '8px',
              ['.holiday-total-year-detail-title']: {
                color: 'rgba(0, 0, 0, 0.84)',
                fontSize: '16px',
                fontWeight: '500',
              },
              ['.total-days-export']: {
                display: 'flex',
                ['.holiday-total-year-detail-days']: {
                  fontSize: '14px',
                  padding: '3px 7px',
                  height: '24px',
                  background: '#F7FAFF',
                  lineHeight: '20px',
                  color: 'rgba(0, 0, 0, 0.84)',
                  ['.holiday-total-year-detail-total-days']: {
                    color: '#2A8EFE',
                    fontWeight: '500',
                  },
                },
                ['.holiday-diver']: {
                  display: 'inline-block',
                  height: '12px',
                  marginTop: '8px',
                  marginLeft: '8px',
                  borderRright: '1px solid rgba(0, 0, 0, 0.12)',
                },
                ['.export-buttons']: {
                  alignItems: 'center',
                  padding: '6px 10px',
                  color: '#FFFFFF',
                  fontSize: '12px',
                  borderRadius: '6px',
                  marginLeft: '8px',
                  background: '#FF6400',
                  height: 'auto',
                  lineHeight: 'normal',
                  ['.card-icon ']:{
                    marginRight: '2px',
                  }
                },
                ['.disabled-btn']: {
                  alignItems: 'center',
                  padding: '3px 10px',
                  fontSize: '12px',
                  borderRadius: '6px',
                  marginLeft: '10px',
                  backgroundColor: '#fff',
                  height: 'auto',
                  lineHeight: 'normal',
                },
              },
            },
            ['.holiday-detail-table-wrapper']: {
              paddingLeft: '8px',
              ['.ant-table-thead>tr>th']: {
                background: '#f0f2f3',
                borderLeft: '1px solid #fff',
                borderRight: '1px solid #fff',
              },
              ['.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters']: {
                padding: '0px',
              },
              ['.ant-table.ant-table-small']: {
                borderRadius: '8px',
              },
              ['.name-ldap']: {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }
            },
          },
        },
        ['.tips-icon']: {
          position: 'absolute',
          top: '25px',
          right: '50px',
          paddingRight: '16px',
          lineHeight: '16px',
          color: 'rgba(0, 0, 0, 0.36)',
          fontSize: '12px',
          height: '16px',
          lineHeight: '16px',
          borderRight: '1px solid rgba(0, 0, 0, 0.12)',
          ['.card-icon']: {
            marginRight: '2px',
          },
        }
      }


    },
  ]);
};

export default useStyle;
